import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { Box, Text } from '@mantine/core';
import {
  DuelTab,
  DuelTabActive,
  NewsTab,
  NewsTabActive,
  ChatTab,
  ChatTabActive,
  SearchTab,
  SearchTabActive,
  PracticeTab,
  PracticeTabActive,
} from '../../assets/images';
import { useTheme } from '../../context/themeProvider';
import './styles.css';
import UniversalVideoModal from '../../molecules/VideoModal';
import { userStore } from '../../stores/userStore';
import { callGETAPI, callPOSTAPI } from '../../utils/NetworkUtil';

const VIDEO_URLS = {
  duel: 'https://d25d0jk908q61f.cloudfront.net/Duel%20-%20Moving%20Away.mp4',
  chat: 'https://d25d0jk908q61f.cloudfront.net/Ask%20a%20Book%20-%20Moving%20Away.mp4',
  news: 'https://d25d0jk908q61f.cloudfront.net/News%20-%20Moving%20Away.mp4',
  practice: 'https://d25d0jk908q61f.cloudfront.net/Practise%20-%20Moving%20Away.mp4',
  pyq: 'https://d25d0jk908q61f.cloudfront.net/PYQ%20-%20Moving%20Away.mp4',
};

const NavItem = memo(({ to, icon, activeIcon, label, isActive, handleClick, colorScheme, linkStyle }) => (
  <NavLink
    to={to}
    style={{ ...linkStyle, color: linkStyle.color(to) }}
    className={isActive(to) ? (colorScheme === 'dark' ? 'dark-theme-active' : 'light-theme-active') : ''}
    onClick={(e) => handleClick(e, to)}
  >
    <img 
      src={isActive(to) ? activeIcon : icon} 
      alt={`${label} icon`} 
      width="24" 
      height="24"
      loading="lazy"
    />
    <div className="label text-xs font-poppins">
      {label}
    </div>
  </NavLink>
));

const Navbar = () => {
  const location = useLocation();
  const userData = userStore((state) => state.userData);
  const userId = userData?.user || null;
  const { colors, colorScheme } = useTheme();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');
  const [currentTab, setCurrentTab] = useState(location.pathname);
  const [intendedTab, setIntendedTab] = useState(null);
  const [canNavigate, setCanNavigate] = useState(true);
  const [pageEntryTime, setPageEntryTime] = useState(Date.now());
  const [showVideoEligible, setShowVideoEligible] = useState(true);
  const [featureCounts, setFeatureCounts] = useState({});

  const navigationTimerRef = useRef(null);
  const isNewUser = localStorage.getItem('new_user') === 'true';

  const fetchVideoCount = useCallback(() => {
    if (userId) {
      callGETAPI(
        `${process.env.REACT_APP_BASE_URL}/api/fetch-all-feature-count/?user_id=${userId}`,
        (resp) => {
          setFeatureCounts(resp.data);
        },
        (err) => {
          console.error('Error fetching video count:', err);
        }
      );
    }
  }, [userId]);

  useEffect(() => {
    fetchVideoCount();
  }, [fetchVideoCount]);

  useEffect(() => {
    setCurrentTab(location.pathname);
    setPageEntryTime(Date.now());
    setShowVideoEligible(true);
    setCanNavigate(false);

    if (navigationTimerRef.current) {
      clearTimeout(navigationTimerRef.current);
    }

    navigationTimerRef.current = setTimeout(() => {
      setCanNavigate(true);
      setShowVideoEligible(false);
    }, 15000);

    return () => {
      if (navigationTimerRef.current) {
        clearTimeout(navigationTimerRef.current);
      }
    };
  }, [location.pathname]);

  const updateFeatureCount = async (feature) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/update-feature-count/`;
    const payload = {
      user_id: userId,
      feature: feature,
    };

    try {
      await callPOSTAPI(
        url,
        payload,
        () => {
          fetchVideoCount();
        },
        (error) => {
          console.error('Error updating feature count:', error);
        }
      );
    } catch (error) {
      console.error('Error in API call:', error);
    }
  };

  const handleClick = useCallback((e, to) => {
    e.preventDefault();

    const now = Date.now();
    const timeDiff = now - pageEntryTime;
    const currentFeature = currentTab.slice(1);

    if (isNewUser) {
      if (timeDiff >= 15000) {
        navigate(to);
        setCurrentTab(to);
        setPageEntryTime(now);
        setShowVideoEligible(false);
      } else {
        if ((featureCounts[currentFeature] || 0) < 3 && showVideoEligible) {
          setCurrentVideo(VIDEO_URLS[currentFeature] || '');
          setShowModal(true);
          updateFeatureCount(currentFeature);
          setIntendedTab(to);
        } else {
          navigate(to);
          setCurrentTab(to);
          setPageEntryTime(now);
          setShowVideoEligible(true);
        }
      }
    } else {
      navigate(to);
      setCurrentTab(to);
      setPageEntryTime(now);
    }
  }, [navigate, currentTab, pageEntryTime, featureCounts, showVideoEligible, isNewUser]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setIntendedTab(null);
  }, []);

  const isActive = useCallback((path) => {
    return location.pathname === path || (path === '/pyq' && location.pathname.startsWith('/pyq'));
  }, [location.pathname]);

  const linkStyle = {
    textDecoration: 'none',
    color: (path) =>
      isActive(path)
        ? colorScheme === 'dark'
          ? '#ffffff'
          : '#000000'
        : colorScheme === 'dark'
        ? '#8B8B8B'
        : '#747474',
    textAlign: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 0',
  };

  const boxClass = `flex justify-around py-2.5 bg-background border-t ${
    colorScheme === 'dark' ? 'border-[#2E2E2E]' : ''
  } shadow-[0px_-1px_4px_0px_#0000001F] dark:shadow-[0_-2px_10px_rgba(0,_0,_0,_0.1)] absolute bottom-0 left-0 right-0 w-full z-[999]`;

  const navItems = [
    { to: '/duel', icon: DuelTab, activeIcon: DuelTabActive, label: 'Duel' },
    { to: '/news', icon: NewsTab, activeIcon: NewsTabActive, label: 'News' },
    { to: '/chat', icon: ChatTab, activeIcon: ChatTabActive, label: 'Chat' },
    { to: '/pyq', icon: SearchTab, activeIcon: SearchTabActive, label: 'PYQs' },
    { to: '/practice', icon: PracticeTab, activeIcon: PracticeTabActive, label: 'Practice' },
  ];

  return (
    <>
      <div className={boxClass}>
        {navItems.map((item) => (
          <NavItem
            key={item.to}
            {...item}
            isActive={isActive}
            handleClick={handleClick}
            colorScheme={colorScheme}
            linkStyle={linkStyle}
          />
        ))}
      </div>

      {showModal && (
        <UniversalVideoModal 
          isOpen={showModal} 
          onClose={handleCloseModal} 
          videoUrl={currentVideo} 
          title="You might be missing out!" 
        />
      )}
    </>
  );
};

export default memo(Navbar);
import React, { useState, useEffect, Suspense, lazy } from 'react';
import CacheClearer from './CacheClearer';
import CacheBuster from 'react-cache-buster';
import { MantineProvider } from '@mantine/core';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import LayoutWrapper from './utils/LayoutWrapper';
import '@mantine/core/styles.css';
import packageInfo from '../package.json';
import { NewsProvider } from './context/newsProvide';
import { ThemeProvider, useTheme } from './context/themeProvider';
import useInstallPromptStore from './stores/eventListenerStore';
import branch from 'branch-sdk';
import { useLocation } from 'react-router-dom';

// Lazy load all components
const HomePage = lazy(() => import('./components/HomePage'));
const AccountDetailsPage = lazy(() => import('./components/AccountDetails'));
const LoginPage = lazy(() => import('./components/Login'));
const SignupPage = lazy(() => import('./components/SignUp'));
const OnboardingPage = lazy(() => import('./components/Onboarding'));
const DuelPage = lazy(() => import('./components/Duel'));
const DuelState = lazy(() => import('./components/Duel/DuelState'));
const ChatPage = lazy(() => import('./components/Chat'));
const PyqPage = lazy(() => import('./components/Pyqs'));
const LatestNews = lazy(() => import('./components/News/latestNews'));
const ProfilePage = lazy(() => import('./components/Profile'));
const NewsDetailPage = lazy(() => import('./components/News/newsDetailPage'));
const NewsPyqPage = lazy(() => import('./components/News/newsPyqPage'));
const AccountDetailsForm = lazy(() => import('./components/SignUp/AccountDetailsForm'));
const ChatScreen = lazy(() => import('./components/Chat/TutorChat/ChatScreen'));
const EditProfile = lazy(() => import('./components/Profile/EditProfile'));
const PyqSearch = lazy(() => import('./components/Pyqs/PyqSearch'));
const MatchGame = lazy(() => import('./components/Duel/MatchGame/MatchGame'));
const Matching = lazy(() => import('./components/Duel/MatchGame/Matching'));
const DuelStart = lazy(() => import('./components/Duel/MatchGame/DuelStart'));
const DuelResults = lazy(() => import('./components/Duel/MatchGame/DuelResults'));
const DuelReview = lazy(() => import('./components/Duel/DuelReview'));
const NewsMcqPage = lazy(() => import('./components/News/newsMCQpage'));
const McqDiscussAi = lazy(() => import('./components/Chat/TutorChat/McqDiscussAI'));
const SelectBook = lazy(() => import('./components/Chat/AskABook/SelectBook'));
const BookChat = lazy(() => import('./components/Chat/AskABook/BookChat'));
const DuelFriendsList = lazy(() => import('./components/Duel/DuelFriendsList'));
const CustomizePractice = lazy(() => import('./components/Practice/CustomizePractice'));
const PracticeQuestions = lazy(() => import('./components/Practice/PracticeQuestions'));
const TabsComp = lazy(() => import('./components/Practice/TabsComp'));
const NewTest = lazy(() => import('./components/Practice/MockTest/NewTest'));
const OngoingTest = lazy(() => import('./components/Practice/MockTest/OngoingTest'));
const TestResult = lazy(() => import('./components/Practice/MockTestResults'));
const ReviewAnswer = lazy(() => import('./components/Practice/MockTestResults/ReviewAnswere'));
const ChangeTheme = lazy(() => import('./molecules/ChangeTheme'));
const MockTestInsights = lazy(() => import('./components/Practice/MockTestInsights'));
const SparkTestResults = lazy(() => import('./components/SparkTest'));
const SparkModal = lazy(() => import('./components/SparkTest/SparkTestModal'));
const TestDetails = lazy(() => import('./components/TestDetails'));
// Loading component for Suspense
const LoadingSpinner = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="relative">
      <div className="w-12 h-12 border-4 border-blue-200 rounded-full"></div>
      <div className="h-12 w-12 border-t-2 border-b-2 border-[#6374FA] rounded-full animate-spin absolute top-0 left-0"></div>
    </div>
  </div>
);

const AppContent = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { colorScheme } = useTheme();
  const navigate = useNavigate();
  const [deepLinkProcessed, setDeepLinkProcessed] = useState(false);
  const isSignupNewUser = localStorage.getItem('is_signup_newUser') === 'true';
  const setDeferredPrompt = useInstallPromptStore((state) => state.setDeferredPrompt);
  const userData = localStorage.getItem('userData');
  const location = useLocation();

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      window.sessionStorage.setItem('deferredPromptFired', true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [setDeferredPrompt]);

  useEffect(() => {
    if (userData) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData]);
  // comment
  useEffect(() => {
    try {
      branch.data((err, data) => {
        console.log('branch data', data);

        if (err) {
          console.error('Error getting deep link data:', err);
          if (!sessionStorage.getItem('hasReloaded')) {
            sessionStorage.setItem('hasReloaded', 'true');
            window.location.reload();
          }
          return;
        }

        if (data === null || data === undefined) {
          window.location.reload();
          return;
        }

        const linkData = data.data_parsed;
        if (!deepLinkProcessed && linkData) {
          let path = '';
          let state = {};

          switch (linkData.navigate_to) {
            case 'news/id':
              path = `news/${linkData.data.id}`;
              state = {
                newsItem: linkData.data,
                newsType: linkData.newsType,
              };
              break;

            case 'news/':
              path = '/news';
              state = {};
              break;

            case '/duel-friends':
              path = '/duel-friends';
              state = {
                inviterId: linkData.inviter_id,
                inviterName: linkData.inviter_name,
                isInvite: true,
              };
              break;

            default:
              return;
          }

          if (isLoggedIn) {
            navigate(path, { state });
          } else {
            localStorage.setItem('intendedDestination', JSON.stringify({ path, state }));
            navigate('/login');
          }
          setDeepLinkProcessed(true);
        }
      });
    } catch (error) {
      console.error('Error getting deep link data:', error);
      window.location.reload();
    }
  }, [navigate, deepLinkProcessed, isLoggedIn]);

  useEffect(() => {
    if (isSignupNewUser) {
      navigate('/signupdetails');
    } else if (isLoggedIn) {
      const intendedDestination = localStorage.getItem('intendedDestination');
      if (intendedDestination) {
        const { path, state } = JSON.parse(intendedDestination);
        navigate(path, { state });
        setTimeout(() => {
          localStorage.removeItem('intendedDestination');
        }, 2000);
      }
    }
  }, [isLoggedIn, isSignupNewUser, navigate]);

  const PrivateRoute = ({ element, isLoggedIn }) => {
    const location = useLocation();

    if (!isLoggedIn) {
      return <Navigate to="/login" state={{ from: location.pathname }} replace />;
    }

    return element;
  };

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={{ colorScheme }}>
      <CacheClearer />
      <LayoutWrapper>
        {location.pathname !== '/signupdetails' && <SparkModal />}
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={isLoggedIn ? <Navigate to={location.state?.from || '/news'} replace /> : <LoginPage />} />
            <Route path="/profile" element={<PrivateRoute element={<ProfilePage />} isLoggedIn={isLoggedIn} />} />
            <Route path="/edit-profile" element={<PrivateRoute element={<EditProfile />} isLoggedIn={isLoggedIn} />} />
            <Route path="/signup" element={isLoggedIn ? <Navigate to={location.state?.from || '/news'} replace /> : <SignupPage />} />
            <Route
              path="/signupdetails"
              element={isLoggedIn && !isSignupNewUser ? <Navigate to={location.state?.from || '/news'} replace /> : <AccountDetailsForm />}
            />
            <Route path="/accountDetails" element={<PrivateRoute element={<AccountDetailsPage />} isLoggedIn={isLoggedIn} />} />
            <Route path="/onboarding" element={isLoggedIn ? <Navigate to={location.state?.from || '/news'} replace /> : <OnboardingPage />} />
            {/* Rest of the routes remain unchanged */}
            <Route path="/news" element={<PrivateRoute element={<LatestNews />} isLoggedIn={isLoggedIn} />} />
            <Route path="/news/:id" element={<NewsDetailPage />} />
            <Route path="/news/pyq/:id" element={<NewsPyqPage />} />
            <Route path="/news/mcq/:id" element={<NewsMcqPage />} />
            <Route path="/duel" element={<PrivateRoute element={<DuelPage />} isLoggedIn={isLoggedIn} />} />
            <Route path="/duelState" element={<DuelState />} />
            <Route path="/chat" element={<PrivateRoute element={<ChatPage />} isLoggedIn={isLoggedIn} />} />
            <Route path="/chat/mcq/" element={<McqDiscussAi />} />
            <Route path="/chat/new/" element={<ChatScreen />} />
            <Route path="/chat/:id" element={<ChatScreen />} />
            <Route path="/pyq" element={<PrivateRoute element={<PyqPage />} isLoggedIn={isLoggedIn} />} />
            <Route path="/pyqSearch" element={<PyqSearch />} />
            <Route path="/practice" element={<PrivateRoute element={<TabsComp />} isLoggedIn={isLoggedIn} />} />
            <Route path="/online-match-game" element={<MatchGame />} />
            <Route path="/matching" element={<Matching />} />
            <Route path="/duel-start" element={<DuelStart />} />
            <Route path="/duel-results" element={<DuelResults />} />
            <Route path="/duel-review" element={<DuelReview />} />
            <Route path="/book-chat/select-book" element={<SelectBook />} />
            <Route path="/book/:id" element={<BookChat />} />
            <Route path="/duel-friends" element={<DuelFriendsList />} />
            <Route path="/customize-practice" element={<CustomizePractice />} />
            <Route path="/practice-questions" element={<PracticeQuestions />} />
            <Route path="/review" element={<DuelReview />} />
            <Route path="/new-test" element={<NewTest />} />
            <Route path="/mock-test" element={<OngoingTest />} />
            <Route path="/test-result" element={<TestResult />} />
            <Route path="/test-review" element={<ReviewAnswer />} />
            <Route path="/change-theme" element={<ChangeTheme />} />
            <Route path="/mock-test-insights" element={<MockTestInsights />} />
            <Route path="/spark-test-results" element={<SparkTestResults />} />
            <Route path="/spark-test-details" element={<TestDetails />} />
          </Routes>
        </Suspense>
      </LayoutWrapper>
    </MantineProvider>
  );
};

const App = () => {
  return (
    <CacheBuster currentVersion={packageInfo.version} isEnabled={true} isVerboseMode={false}>
      <ThemeProvider>
        <NewsProvider>
          <Router>
            <AppContent />
          </Router>
        </NewsProvider>
      </ThemeProvider>
    </CacheBuster>
  );
};

export default App;

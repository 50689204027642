const themeColors = {
  light: {
    background: '#FFFFFF',
    text: '#1A1A1A',
    cardBackground: '#FFFFFF',
    cardText: '#1A1A1A',
    noDataText: '#4A4A4A',
    subtleText: '#6B7280',
    borderColor: '#E5E7EB',
    primary: '#3B82F6',
    highlight: '#E1EBFF',
    coloredButton: '#6374FA',
    arrowColor: '#1A1A1A',
    correctAnswer: '#E4FFEF',
    incorrectAnswer: '#FFB6B6',
    border: '#E5E7EB',
    secondary: '#64748B',
    accent: '#3B82F6',
    success: '#22C55E',
    error: '#EF4444',
    warning: '#F59E0B',
    info: '#3B82F6',
    disabled: '#9CA3AF',
    hover: {
      primary: '#2563EB',
      secondary: '#4B5563',
      accent: '#2563EB',
    },
    shadow: 'rgba(0, 0, 0, 0.1)',
    overlay: 'rgba(0, 0, 0, 0.5)',
    divider: '#E5E7EB',
    textGray: '#464646',
  },
  dark: {
    background: '#1A1A1A',
    text: '#F9FAFB',
    cardBackground: '#2C2C2C',
    cardText: '#F9FAFB',
    noDataText: '#A0A0A0',
    subtleText: '#9CA3AF',
    borderColor: '#4B5563',
    primary: '#60A5FA',
    highlight: '#4D5699',
    coloredButton: '#6374FA',
    arrowColor: '#FFFFFF',
    correctAnswer: '#065F46',
    incorrectAnswer: '#991B1B',
    border: '#374151',
    secondary: '#9CA3AF',
    accent: '#60A5FA',
    success: '#059669',
    error: '#DC2626',
    warning: '#D97706',
    info: '#60A5FA',
    disabled: '#6B7280',
    hover: {
      primary: '#3B82F6',
      secondary: '#6B7280',
      accent: '#3B82F6',
    },
    shadow: 'rgba(0, 0, 0, 0.25)',
    overlay: 'rgba(0, 0, 0, 0.75)',
    divider: '#374151',
  },
};

export default themeColors;

export const hideHeaderRoutes = [
  '/onboarding',
  '/login',
  '/',
  '/signup',
  '/signupdetails',
  '/pyqSearch',
  '/duel-review',
  '/online-match-game',
  '/matching',
  '/duel-start',
  '/duel-results',
  '/news/mcq/:id',
  '/news/:id',
  '/book-chat/select-book',
  '/book/:id',
  '/customize-practice',
  '/practice-questions',
  '/review',
  ,
  '/duel-friends',
  '/new-test',
  '/mock-test',
  '/test-result',
  '/test-review',
  '/mock-test-insights',
  '/change-theme',
  '/spark-test-results',
  '/spark-test-details'
  ];
export const hideFooterRoutes = [
  '/onboarding',
  '/login',
  '/',
  '/profile',
  '/news/',
  '/signup',
  '/signupdetails',
  '/chat/:id',
  '/edit-profile',
  '/pyqSearch',
  '/duelState',
  '/news/mcq/',
  '/book-chat/select-book',
  '/book/:id',
  '/duel-results',
  '/news/:id',
  '/duel-friends',
  '/new-test',
  '/mock-test',
  '/test-result',
  '/mock-test-insights',
  '/change-theme',
  '/spark-test-results',
  '/spark-test-details'
];

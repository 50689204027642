import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../molecules/Navbar';
import Header from '../molecules/Header';
import Breadcrumbs from '../molecules/BreadCrumbs/breadcrumbs';
import { hideFooterRoutes, hideHeaderRoutes } from '../constants/hideRoutes';

const LayoutWrapper = ({ children }) => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const location = useLocation();

  const [activeTab, setActiveTab] = useState('latest');
  const shouldHideHeader = hideHeaderRoutes.some((route) => new RegExp(`^${route.replace(':id', '[^/]+')}$`).test(location.pathname));
  const shouldHideFooter =
    hideFooterRoutes.some((route) => new RegExp(`^${route.replace(':id', '[^/]+')}$`).test(location.pathname)) ||
    location.pathname.startsWith('/news/') ||
    location.pathname.startsWith('/chat/') ||
    location.pathname.includes('online-match-game') ||
    location.pathname.includes('customize-practice') ||
    location.pathname.includes('practice-questions') ||
    location.pathname.includes('matching') ||
    location.pathname.includes('duel-start') ||
    location.pathname.includes('duel-review') ||
    location.pathname.includes('review') 

  const showBreadCrumbs = ['/news'];

  const updateWindowDimensions = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <div
      className={`max-w-[${windowDimensions.width <= 768 ? '100vw' : '28rem'}] mx-auto flex flex-col bg-background text-text justify-start items-center relative overflow-hidden`}
      style={{
        height: `${windowDimensions.height}px`,
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px', 
      }}
    >
      {!shouldHideHeader && <Header />}
      {showBreadCrumbs.includes(location.pathname) && <Breadcrumbs setActiveTab={setActiveTab} />}
      <div className="flex-1 w-full bg-background">
        {children}
      </div>
      {!shouldHideFooter && <Navbar />}
    </div>
  );
};

export default LayoutWrapper;

import { create } from 'zustand';

const useModalStore = create((set) => ({
  isShown: false,
  isDismissed: false,
  lastShownTimestamp: null,
  announcementId: null,
  announcementData: null,
  setShown: (state) => set({ isShown: state, lastShownTimestamp: state ? Date.now() : null }),
  setDismissed: (state) => set({ isDismissed: state }),
  setAnnouncementData: (data) => set({ 
    announcementData: data,
    isDismissed: data.dismissed,
    announcementId: data.announcement_id 
  }),
  reset: () => set({ 
    isShown: false, 
    isDismissed: false, 
    lastShownTimestamp: null,
    announcementData: null 
  }),
}));

export default useModalStore;